<template>
    <div ref="noteList">
        <template v-if="loading">
            <LoadingSpinner class="col-12" />
        </template>
        <template v-else-if="Object.keys(items).length == 0">
            <div class="box">
                <span class="text-center">
                    <i>{{ $t('global.no_result') }}</i>
                </span>
            </div>
        </template>
        <template v-else>
            <template v-for="(resultats) in items">        
                <div v-for="result in resultats" :key="result.actes_id">
                    <div class="box mt-2" :key="result.actes_id">
                        <div class="row d-flex">
                            <div class="col-10">
                                <h4 class="mb-0">
                                    {{ result.rang }}{{ getRangTrad(result.rang) }}
                                </h4>
                                <router-link :to="{ name: 'horseFiche', params: { horse_id: result.horse_id }}">{{ result.horse_nom | multiCapitalize }}</router-link>
                                    ({{ result.horse_lieunaissance }}) {{ result.horse_datenaissance }} - {{ result.hipo_name }}
                            </div>
                            <div class="col-2 text-right">
                                <font-awesome-icon class="h4" :icon="['fad', 'arrow-circle-down']" :flip="result.full_display ? 'horizontal' : 'vertical'" @click="result.full_display = !result.full_display" />
                            </div>
                        </div>

                        <transition name="slide-fade">
                            <div v-if="result.full_display">
                                <hr>
                                {{ $t('horse.entraineur') }}: {{ result.trainer_name }}<br>
                                {{ $t('horse.course') }}: {{ result.race_name }}<br>
                                {{ result.race_hour }}
                            </div>
                        </transition>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>


<script type="text/javascript">
    import ActesMixin from "@/mixins/Actes.js"
    import HorseMixin from "@/mixins/Horse.js"
    import _groupBy from 'lodash/groupBy'

	export default {
		name: "ActResults",
        mixins: [ActesMixin, HorseMixin],
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
        },
        props: {
            horse_id: { type: Number, default: 0 }
        },
        data() {
            return {
                items: [],
                loading: true
            }
        },
        created() {
            this.preinit_component()
        },
        methods: {
            async preinit_component() {
                let temp = []
                
                if(this.horse_id > 0) {
                    temp = await this.getHorseResultats(this.horse_id)
                }
                else {
                    temp = await this.getResultats()
                }

                temp = temp.map(h => ({ ...h, full_display: false }))
                this.items = _groupBy(temp, 'actes_state')
                this.loading = false
            },
            getRangTrad(val) {
                if(val <= 4) return this.$t(`perfs.rang_nth.${val}`)
                return this.$t('perfs.rang_nth.4')
            }
        }
	}
</script>
